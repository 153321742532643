.view-pop-loader {
  width: 100%;
  height: 207px;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  gap: 20px;
  position: relative;
}

.protection-zone {
  width: 100%;
  /* height: 207px; */
  background-color: #eaf1f9;
  border-radius: 4px;
  /* border: 2px dashed #2e75c7; */
  padding: 20px;
  /* text-align: center; */
  gap: 20px;
  position: relative;
}

.protection-zone>.close-button {
  position: absolute;
  top: -10px;
  right: 5px;
  padding: 10px;
}

.protection-title {
  font-family: "SSTPro";
  font-size: 18px;
  font-weight: 500;
  color: #35333c;
  margin: 0;
}

.pr-divider {
  width: 100%;
  height: 1px;
  background-color: #e4e7eb;
  margin: 5px 0;
}

.pr-plans {
  font-family: "SSTPro";
  font-size: 14px;
  font-weight: 500;
  color: #757580;
  line-height: 20px;
}

.pr-plans h3 {
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 0.1rem;
}

.pr-text-button {
  font-family: "SSTPro-Roman";
  font-size: 16px;
  color: #2e75c7;
  background-color: transparent;
  width: auto;
  transition: 0.2s ease-in;
}

.manufacturer {
  width: 115px;
}