.modal {
  --bs-body-font-family: 'SSTPro-Roman', sans-serif;
  --bs-modal-color: #35333c;
  font-family: var(--bs-body-font-family);
  color: var(--bs-modal-color);
}

.modal .invalid-feedback {
  font-size: 15px;
}

.modal input {
  font-size: 14px;
}

.modal-content {
  border-radius: 4px;
  width: 770px;
  font-size: 16px;
}

.modal-body {
  padding: 25px 25px;
  gap: 30px;
}

@media only screen and (min-width: 768px) {
  .modal-body {
    padding: 40px 80px;
  }
}

.modal-form-group {
  flex: 1 0 auto;
  min-width: 240px;
  max-width: 285px;
}

.modal_title {
  font-size: 28px;
  color: #35333c;
  font-family: 'SSTPro', sans-serif;
}

.modal_text {
  font-size: 1rem;
  font-family: 'SSTPro-Roman', sans-serif;
}

.modal_close-button {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 10px;
}

.info-link {
  font-size: 16px;
  color: #2e75c7;
  margin-top: 5px;
}

.info-link:hover {
  color: #2e75c7;
  opacity: 0.8;
}

.info-link>.icon {
  font-size: 16px !important;
  margin-right: 2px !important;
}