.form-group-wrapper {
  row-gap: 30px;
  column-gap: 30px;
  grid-template-columns: 1fr;
}
.form-group-wrapper .form-group {
  flex: 1 0 auto;
}
@media only screen and (min-width: 767px) {
  .form-group-wrapper {
    row-gap: 40px;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}