.page-item {
  margin: 0 5px;
}

.page-item .page-link {
  cursor: pointer;
  width: 46px;
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  font-family: "SSTPro-Roman";
  padding: 15px 18px 15px 20px;
  border: solid 1px #e4e7eb;
  background-color: #fff;
  color: #2e75c7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item:last-child .page-link {
  border-radius: 4px;
  margin-left: 15px;
}

.page-item.disabled .page-link {
  color: #878792;
}

.page-item:last-child .page-link span,
.page-item:first-child .page-link span {
  font-size: 24px;
  margin-bottom: 5px;
}

.page-item:first-child .page-link {
  border-radius: 4px;
  margin-right: 15px;
}

.page-item .page-link:hover {
  border: solid 1px #2e75c7;
  background-color: #2e75c7;
  color: #fff;
}

.page-item.active .page-link {
  border: solid 1px #2e75c7;
  background-color: #2e75c7;
  color: #fff;
}