#main {
  margin-top: 60px;
}

button .svg-icon {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-products {
  background-color: #fbfcff;
}

.welcome-block {
  margin-bottom: 40px;
}

.my-products__welcome-title {
  font-family: "SSTPro";
  font-size: 18px;
  font-weight: 500;
  color: rgba(53, 51, 60, 0.8);
}

.my-products__title {
  font-family: "SSTPro";
  font-size: 34px;
  font-weight: 500;
  color: #35333c;
}

.accent-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background-color: #2e75c7;

  font-family: "SSTPro-Roman";
  font-weight: normal;
  color: #fff;
}

.welcome-block .accent-button {
  font-size: 18px;
  width: max-content;
  height: 77px;
  padding: 24px 30px;
}

/* changes for galaxy s8 */
@media (max-width: 480px) {
  .welcome-block .accent-button {
    padding: 24px 20px;
  }
}

/* --- */
/* Product Card */
.product-card {
  width: 100%;
  padding: 25px;
  margin-bottom: 40px;
  border-radius: 4px;
  border: solid 1px #e4e7eb;
  background-color: #fff;
}

/* .product-card>div:first-child {
  height: 200px;
} */

@media (max-width: 768px) {
  .product-card>div:first-child {
    height: auto;
  }
}

.product-card__title {
  font-family: "SSTPro";
  font-size: 20px;
  font-weight: 500;
  color: #35333c;
  margin: 0;
}

.product-card__info {
  margin: 25px 0;
}

.product-card__info-title {
  font-family: "SSTPro";
  font-size: 16px;
  font-weight: 500;
  color: #757580;
  /* Contrast fix v3.4 12/15/2023 IIDs AI41-AI47, AI62-AI68 */
  margin-right: 10px;
  line-height: 20px;
  min-width: max-content;
}

.product-card__info-text {
  font-family: "SSTPro-Roman";
  font-size: 16px;
  color: #757580;
  /* Contrast fix v3.4 12/15/2023 IIDs AI41-AI47, AI62-AI68 */
  line-height: 20px;
}

.product-card__info-text>div {
  min-height: 20px;
}

.product-card__image {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border-radius: 4px;
}

/* toggle-button */
.text-button-wrapper {
  min-height: 40px;
}

.text-button {
  font-family: "SSTPro-Roman";
  font-size: 16px;
  color: #2e75c7;
  background-color: transparent;
  width: auto;
  transition: 0.2s ease-in;
}

.text-button:hover {
  color: #0b5ed7;
}

/* Animation for toggle-button */
.fade-out {
  animation: fadeOut .5s;
  opacity: 0;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn .5s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e4e7eb;
  margin: 23px 0;
}

.product-card__footer {
  gap: 20px;
  row-gap: 10px;
}

@media (max-width: 768px) {
  .product-card__footer {
    gap: 10px;
  }
}

.outline-button {
  height: 48px;
  border-radius: 4px;
  border: solid 1px #e4e7eb;
  background-color: #fff;

  font-family: "SSTPro-Roman";
  font-size: 14px;
  color: #2e75c7;

  width: max-content;
  padding-right: 20px;
  padding-left: 20px;
}

.menu-button {
  width: 35px;
  height: 48px;
  padding-left: 10px;
  margin-left: auto;
  background-color: transparent;
  transition: 0.2s ease-in;
}

.menu-button:hover {
  color: #0b5ed7;
}

.drop-zone {
  width: 100%;
  height: 207px;
  background-color: #eaf1f9;
  border-radius: 4px;
  border: 2px dashed #2e75c7;
  padding: 20px;
  text-align: center;
  gap: 20px;
  position: relative;
}

.drag-over {
  border-color: #1569c9;
  background-color: #d1e3f8;
}

.drop-zone__title {
  font-family: "SSTPro-Roman";
  font-size: 16px;
  color: #2e75c7;
}

.drop-zone .accent-button {
  font-size: 14px;
  width: max-content;
  height: 48px;
  padding: 15px 32px;
}

.drop-zone>.close-button {
  position: absolute;
  top: -10px;
  right: 5px;
  padding: 10px;
}


.product-card__file .product-card__info {
  margin: 0;
}

.product-card__file-preview {
  width: 145px;
  height: 207px;
  border-radius: 4px;
  border: solid 1px #e4e7eb;
  margin-right: 20px;
}

.product-card__file-info {
  gap: 10px;
}

.imagePreview,
.pdfPreview {
  max-width: 100%;
  max-height: 100%;
}

.fileName {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1150px) {
  .fileName {
    max-width: 270px;
  }
}

@media (max-width: 991px) {
  .fileName {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .fileName {
    max-width: 270px;
  }
}

@media (max-width: 480px) {
  .fileName {
    max-width: 200px;
  }
}

.btn-group>.text-button:not(:last-child)::after {
  content: "|";
  margin: 0 10px;
  font-family: SSTPro-Roman;
  font-size: 16px;
  color: #757580;
  /* Contrast fix v3.4 12/15/2023 IIDs AI41-AI47, AI62-AI68 */
}

/* End Product Card */

/* Left side */
.bg-block {
  width: 300px;
  height: auto;
  display: block;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}

.bg-block .bg-line {
  width: 300px;
  height: 100%;
  background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0) 78%);
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: 3;
}

.bg-block .text-img {
  position: absolute;
  font-family: "SSTPro";
  font-size: 20px;
  color: #fff;
  padding: 30px;
  display: block;
  z-index: 4;
  max-width: 300px;
}

.bg-block img {
  max-width: 100%;
  position: relative;
}

@media (max-width: 480px) {
  .bg-block .text-img {
    font-size: 15px;
    padding: 10px;
  }
}

.modal_loading-button {
  color: black !important;
}

.ios-date-input {
  appearance: none;
  padding-left: 0;
  padding-right: 150px;
}

@media (min-width: 576px) and (max-width: 800px) {
  .modal-dialog {
    margin-right: 15px;
    margin-left: 15px;
  }
}