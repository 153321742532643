@media (min-width: 320px) and (max-width: 412px) {
    .about .content h2 {
        font-size: 18px !important;
        margin-bottom: 2px !important;
    }

    .about .content .title {
        font-size: 16px !important;
    }

    .about .form-registration button {
        width: 140px !important;
        padding: 8px 10px !important;
    }
}

@media (max-width: 480px) {
    .fr-h2 {
        width: 196px;
        margin-bottom: 2px;
    }

    .hero-container__info span {
        margin-left: 8px !important;
        font-size: 13px !important;
    }
}

@media (min-width: 359px) and (max-width: 360px) {
    .about .form-registration .fr-guest {
        justify-content: flex-start !important;
    }
}

@media screen and (width > 820px) {
    .customPr {
        padding-right: 120px;
    }

    .tiles {
        max-width: 400px !important;
    }

    .tilesContainer {
        width: 400px !important;
        cursor: pointer;
    }
}

#bgImage {
    height: auto;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
}

.about .form-registration button:hover {
    opacity: 0.8;
    background-color: #CCC !important;
}

.step1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

@media screen and (max-width: 480px) {
    .step1 {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* temp update for zv series */
.zv-list{
    color: #2e75c7;
}
.zv-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
 
.zv-tooltip .tooltiptext {
    visibility: hidden;
    /* width: 120px; */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
 
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}
 
.zv-tooltip:hover .tooltiptext {
    visibility: visible;
}