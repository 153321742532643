.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 20em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    font-size: 15px !important
  }
  
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .banner {
    position: relative;
    padding: 10px 20px;
    animation: loader 1s cubic-bezier(0.5, 0.1, 0.15, 1) alternate infinite;
  }
  
  @keyframes loader {
    0% {
      letter-spacing: -1px;
    }
    100% {
      letter-spacing: 15px;
    }
  }
  
  .banner::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transform: skewX(-15deg);
    background-color: #343a40;
  }
  