@font-face {
  font-family: "spri";
  src: url("fonts/spri.eot?fdc4kr");
  src:
    url("fonts/spri.eot?fdc4kr#iefix") format("embedded-opentype"),
    url("fonts/spri.ttf?fdc4kr") format("truetype"),
    url("fonts/spri.woff?fdc4kr") format("woff"),
    url("fonts/spri.svg?fdc4kr#spri") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="spri-icon-"],
[class*=" spri-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "spri" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spri-icon-info:before {
  content: "\e909";
  color: #2e75c7;
}

.spri-icon-Arrow-next:before {
  content: "\e907";
  font-size: 12px;
}
.spri-icon-Arrow-previous:before {
  content: "\e908";
  font-size: 12px;
}
.spri-icon-View:before {
  content: "\e906";
  color: #2e75c7;
}
.spri-icon-Plus:before {
  content: "\e903";
  color: #fff;
}
.spri-icon-Menu:before {
  content: "\e904";
  color: #3561a3;
}
.spri-icon-Attachment:before {
  content: "\e905";
  color: #2e75c7;
}
.spri-icon-edit:before {
  content: "\e900";
}
.spri-icon-remove:before {
  content: "\e901";
}
.spri-icon-xmark-solid:before {
  content: "\e902";
}
