.page-container {
  display: grid;
  grid-template-columns: 18% 1fr;
}

.drawer-container {
  border: 0px solid rgba(100, 100, 100, 1);
  border-radius: 0px;
  box-shadow: 3px 5px 25px 2px rgba(0, 0, 0, 0.1);
  /* width: 18%; */
}

.drawer-sony-logo {
  padding: 26px 60px 40px 80px;
}

.my-products-drawer-menu-container {
  padding: 20px 60px 40px 50px;
}

.my-products-menu-text {
  display: inline-block;
  margin-left: 10px;
  color: rgb(49, 95, 157);
  font-size: 18px;
}

.my-profile-drawer-menu-container {
  margin-top: 200px;
  padding: 20px 60px 20px 50px;
}

.my-products-menu-text {
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
}

.logout-drawer-menu-container {
  padding: 20px 60px 40px 50px;
}

.logout-menu-text {
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.my-products-container {
  margin: 20px;
}

.register-product-button {
  color: rgb(51, 94, 153);
  border: 1px solid #e5e5e5;
  background-color: transparent;
  padding: 16px 60px;
  font-size: 16px;
}

.products-list {
  border: 0px solid rgba(100, 100, 100, 1);
  border-radius: 0px;
  box-shadow: 1px 1px 8px 0px rgba(20, 20, 20, 0.2);
  padding: 16px;
  margin-top: 20px;
}

.product-data-container {
  display: grid;
  grid-template-columns: 80% 1fr;
}

.product-name {
  color: black;
  font-size: 20px;
  margin-bottom: 8px;
}

.serial-number,
.registration-date,
.purchased-date,
.delivered-date,
.retailer-name {
  color: #858585;
  font-size: 14px;
  margin-bottom: 6px;
}

.retailer-name {
  margin-bottom: 24px;
}

.pop {
  display: inline-block;
  color: black;
  font-size: 14px;
  margin-bottom: 12px;
  color: rgb(51, 94, 153);
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.pop-section {
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.close-button {
  color: white;
  border: none;
  background-color: rgb(183, 183, 183);
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  padding: 20px 40px;
  margin-top: 20px;
}

.upload-pop-container {
  border: 0px solid rgba(100, 100, 100, 1);
  border-radius: 0px;
  box-shadow: 7px 7px 12px 0px rgba(0, 0, 0, 0.12);
  width: 50%;
  padding: 16px;
  margin: 50px;
}

.dropdown-item:hover {
  background-color: #ccc !important;
}

.file-input-box-container {
  margin: 24px;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  padding: 10px 20px;
}

.file-input-label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  padding: 14px 18px;
  background-color: rgb(51, 94, 153);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-error {
  color: rgb(215, 33, 33);
  font-size: 18px;
}

.horizontal-line {
  border-top: 1px solid #d6d6d6;
}

.buttons-container {
  margin-top: 22px;
}

.button {
  background-color: rgb(51, 94, 153);
  border: none;
  padding: 20px 10px;
  color: white;
  font-weight: bold;
  border-radius: 2px;
  border-color: rgb(88, 101, 245);
  margin-right: 20px;
}

.ellipse-icon-container {
  float: right;
  margin-right: 22px;
  margin-top: 14px;
}

.element {
  width: 75%;
  margin-top: 30px;
  margin-bottom: 50px;
}

.backdrop-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1201;
  color: #fff
}

.invalid-style {
  background: rgb(220, 53, 69);
  padding: 10px 15px;
  border-radius: 6px;
  color: #fff;
  display: block
}

/* changes for galaxy s8 */
.register-add-button i {
  font-size: 20px;
  margin-right: 20px;
}

@media (max-width: 480px) {
  .register-add-button i {
    font-size: 20px;
    margin-right: 10px;
  }
}

/* --- */