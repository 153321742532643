.circule {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background: #35333c;
    color: #ffffff;
    border-radius: 50%;
    font-family: 'SSTPro-Roman';
    font-size: 20px;
    margin: 0 15px 0 0;
}

.circule.active {
    background: #2e75c7;
    font-family: 'SSTPro';
}

@media (max-width: 480px) {
    .circule {
        width: 34px;
        height: 34px;
        min-width: 34px;
        min-height: 34px;
        font-size: 18px;
    }
}

.circule path {
    fill: white;
}

.returnBtn {
    align-items: center;
    max-width: 70px;
}

.returnBtn button {
    background: none;
    color: #35333c;
    display: block;
    margin: 0 0 0 7px;
    padding: 0;
    width: auto;
}

.update-pop-button {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    font-size: 14px;
    width: 160px;
    border: 1px solid #ced4da !important;
    background: #ffffff;
    color: #35333c;
}

.update-pop-inp {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}