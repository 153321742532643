.login-button {
    padding: 8px 10px !important;
    max-width: 112px !important;
}

.login-spanish {
    max-width: 150px !important;
    padding: 8px 10px !important;
}

/* @media screen and (max-width: 1600px) {
    .sonyLogo {
        width: 105px;
    }
}

@media screen and (max-width: 767px) {
    .sonyLogo {
        left: 16px;
        top: 24px;
        width: 72px;
    }
} */
@media (max-width:767px) {
    .sonyLogo {
        display: block;
        width: 150px;
        max-width: 100%;
        margin: 0 auto;
    }
}