/*----------------------------------
#Font
-------------------------------*/
@font-face {
  font-family: 'SSTPro';
  src: local('SSTPro'), local('SSTPro'),
    url(../fonts/SSTPro-Medium.otf);
}

@font-face {
  font-family: 'SSTPro-Roman';
  src: local('SSTPro-Roman'), local('SSTPro-Roman'),
    url(../fonts/SSTPro-Roman.otf);
}

@font-face {
  font-family: 'SSTLight';
  src: local('SSTLight'), local('SSTLight'),
    url(../fonts/SSTLight.TTF);
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  --bs-body-font-family: 'SSTPro', sans-serif;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1200px !important;
}

body {
  color: #444444;
}

a {
  color: #2e75c7;
  text-decoration: none;
}

a:hover {
  color: #0dd3fe;
  text-decoration: none;
}

p {
  color: #35333c;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'SSTPro';
  font-weight: 500;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #2e75c7;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #03d1fe;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #fff;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  height: 44px;
  padding: 0;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 996;
}

#topbar.topbar-scrolled {
  top: -44px;
}

#topbar .contact-info i {
  font-style: normal;
  color: #2e75c7;
}

#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #444444;
}

#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

#topbar .contact-info i a:hover {
  color: #2e75c7;
}

#topbar .social-links a {
  color: #62787d;
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
  border-left: 1px solid #e9e9e9;
}

#topbar .social-links a:hover {
  color: #2e75c7;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  z-index: 1001;
  transition: all 0.5s;
  background: #000000;
}

#header.header-scrolled {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  top: 0;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #354144;
  text-decoration: none;
  display: flex;
}

#header .logo h1 a span,
#header .logo h1 a:hover span {
  color: #2e75c7;
}

/* #header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
} */

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
  padding-right: 30px;
  /* height: 70px; */
}

.navbar button {
  background: #2e75c7;
  padding: 8px 30px 10px;
  margin-left: 30px;
  font-size: 18px;
  color: #ffffff;
  font-family: SSTPro;
  border: none;
  border-radius: 3px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 10px 20px;
  font-family: 'SSTLight';
  font-size: 16px;
  color: #ffffff;
  white-space: nowrap;
  text-transform: uppercase;
  transition: 0.3s;
}

.navbar a img {
  width: 32px;
  height: auto;
  margin-right: 5px;
}

.navbar .arrow-bottom {
  max-width: 10px;
  height: 16px;
  margin-left: 10px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 10px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #ffffff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 0px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #000000;
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.hero-container__info .slider-info {
  display: flex;
  width: 18px;
  height: auto;
  align-items: center;
  justify-content: center;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  opacity: 0.8;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.navbarFooter.navbar .dropdown ul {
  /* display: block;
  position: absolute; */
  left: -100px;
  bottom: calc(100% + 30px);
  top: -200%;
  background: #3c3c42;
}

.navbarFooter.navbar .dropdown:hover>ul {
  bottom: 100%;
  top: -200%;
  left: -143px;
}

.navbarFooter.navbar .dropdown .dropdown:hover>ul {
  bottom: 0;
  top: -200%
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #354144;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  #navbar.navbar {
    padding-right: 10px;
  }

  /* #header .logo img {
    max-width: 85px;
  } */

  .navbar {
    display: flex;
    flex-wrap: nowrap;
    padding-right: 0px;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(31, 38, 39, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #354144;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #2e75c7;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #2e75c7;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: auto;
  background-color: #000000;
  overflow: hidden;
  position: relative;
  padding: 0;
  margin-top: 70px;
}

#hero .carousel-item {
  width: 100%;
  min-height: 316px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

#login #hero .carousel-item {
  min-height: 600px;
}

#hero .carousel-item img {
  max-width: 100%;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .carousel-content {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 316px;
  width: 100%;
  position: relative;
  background-position: right;
}

#login #hero .carousel-content {
  min-height: 600px;
}

#hero h2 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 500;
  margin: 40px 0 30px;
  max-width: 485px;
}

#login #hero h2 {
  font-size: 40px;
  line-height: normal;
  position: relative;
  z-index: 9;
  margin-top: 160px;
  margin: 40px 0 40px;
}

@media (max-width: 480px) {
  #login #hero h2 {
    margin-top: 60px;
    font-size: 28px;
  }
}

#hero p {
  width: 80%;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .bg {
  background-size: contain;
  /* height: 316px; */
  min-height: 316px;
  width: 100%;
  /* background-position: center; */
  display: block;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(to bottom, black 5%, #d9050500 50%);
}

#carouselExampleIndicators {
  margin-top: 255px;
}

@media only screen and (min-width: 768px) {
  #hero .carousel-inner .bg {
    background-image: linear-gradient(to right, black 5%, #d9050500 50%);
  }

  #carouselExampleIndicators {
    margin-top: 0;
  }
}

#login #hero .carousel-inner .bg {
  min-height: 600px;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .carousel-indicators li {
  list-style-type: none;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 4px;
  transition: ease-in;
  opacity: 0.7;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background-color: #ffffff;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 5px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  background: #2e75c7;
  animation-delay: 0.8s;
}

.hero-container__info {
  position: absolute;
  display: block;
  z-index: 2;
}

#login .hero-container__info {
  max-width: 485px;
  /* position: relative; */
  z-index: 3;
  margin-top: 120px;
}

.hero-container__info>.d-flex {
  margin-top: 5px;
}

#login .hero-container__info>.d-flex {
  margin-top: 5px;
}

.hero-container__info span {
  margin-left: 15px;
  font-size: 18px;
  font-family: 'SSTPro-Roman';
  color: #ffffff;
  white-space: nowrap;
}

.hero-container__info span.text-style-1 {
  margin-left: 0px;
  font-size: 10px;
  font-family: 'SSTPro-Roman';
  color: #ffffff;
}

.hero-container__info h2 {
  font-size: 36px;
}

#hero .btn-get-started:hover {
  background: #019cbe;
}

@media (max-width: 768px) {
  /* #hero {
    height: 90vh;
  }

  #hero .carousel-item {
    height: 90vh;
  } */

  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section,
.info {
  padding: 20px 0;
}

.section-bg {
  background-color: #000000;
  padding: 40px 0px 20px;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #354144;
  position: relative;
  z-index: 2;
}

.section-title span {
  position: absolute;
  top: 30px;
  color: #f3f5f5;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-title span {
    font-size: 38px;
  }

  .section-bg {
    padding: 20px 0px 40px;
  }

  #login .hero-container__info {
    margin-top: 0px;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #35333c;
  font-family: 'SSTPro';
}

.about .content .title {
  font-size: 18px;
  color: #35333c;
  font-family: 'SSTPro-Roman';
}

button {
  padding: 8px 0px 10px;
  margin-left: 30px;
  font-size: 18px;
  font-family: 'SSTPro';
  border: none;
  width: 185px;
  transition: .3s;
}

#login .hero-container__info a {
  width: 215px;
  height: 43px;
  background-color: #2e75c7;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'SSTPro';
  transition: 0.3s;
  font-size: 18px;
}

#login .hero-container__info a:hover {
  opacity: 0.8;
}

#login .hero-container__info a.account {
  background: none;
  border: solid 1px #fff;
}

#login .hero-container__info a.account:hover {
  background-color: #f1f1f1;
  color: #35333c
}

@media (max-width: 768px) {

  #login .hero-container__info a {
    width: 150px;
  }
}

#login button {
  margin-left: 0;
}

#navbarHome.navbar {
  padding-right: 0px
}

#navbar button,
.navbar button {
  margin-left: 30px;
  max-width: 108px;
  transition: .3s;
  max-height: 43px;
}

#navbar button:hover,
.navbar button:hover {
  opacity: 0.8;
}

.about .form-registration button:hover {
  opacity: 0.8;
}

.about .form-registration .step .circule {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: #35333c;
  color: #ffffff;
  border-radius: 50%;
  font-family: 'SSTPro-Roman';
  font-size: 20px;
  margin: 0 15px 0 0;
}

.about .form-registration .step p {
  color: #35333c;
  font-size: 18px;
}

.about .form-registration .step p.active {
  color: #2e75c7;
}

.about .form-registration .step .circule.active {
  background: #2e75c7;
  font-family: 'SSTPro';
}

.about .form-registration button.login {
  background: #2e75c7;
  border-radius: 3px;
  color: #ffffff;
}

.about .form-registration .hr-top {
  margin: 20px 0 30px;
}

.about .form-registration .hr-bottom {
  margin: 40px 0 20px;
}

.about .block-continue {
  margin-top: 42px;
}

.Rectangle {
  width: 30px;
  height: 2px;
  margin: 20px 24px 18px;
  background-color: #e7e7e7;
}

@media (max-width: 480px) {
  .about .form-registration .step .circule {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    font-size: 18px;
  }

  .about .form-registration .step p {
    color: #35333c;
    font-size: 14px;
  }

  .Rectangle {
    margin: 20px 24px 18px 0px;
  }

  section {
    padding: 40px 0;
  }
}

button.account {
  border: 1px solid #ced4da !important;
  background: #ffffff;
  color: #35333c;
  border-radius: 3px;
}

button.account:hover {
  background-color: #f1f1f1;
}

.about .form-registration .info {
  font-size: 13px;
  font-family: 'SSTPro-Roman';
}

.about .form-registration .info svg {
  margin-bottom: 3px;
}

.about .form-registration .info span {
  font-family: 'SSTPro';
}

.about .form-registration form input,
.about .form-registration form .form-select {
  min-width: 300px;
}

.about .form-registration form input[type="radio"],
.about .form-registration form input[type="checkbox"] {
  min-width: 16px;
}

.about .form-registration form button.return {
  background: none;
  margin: 0 0 0 7px;
  display: block;
  width: auto;
  color: #35333c;
  padding: 0px;
}

.about .form-registration form label.form-check-label {
  font-size: 14px;
}

.about .form-registration form label {
  color: #35333c;
  margin-bottom: 4px;
  font-size: 16px;
}

.about .form-registration form button.Submit {
  display: block;
  padding: 10px 30px;
  width: auto;
  border-radius: 3px;
}

form .margin-label {
  margin-top: 42px;
}

.about .form-registration form a {
  text-decoration: underline;

}

form #SerialHelp,
form #ModelHelp {
  font-size: 16px;
  color: #2e75c7;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'SSTPro-Roman'
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about #Guest {
  cursor: pointer;
  transition: .3s;
}

.about #Guest:hover {
  color: #2e75c7
}

#loginMobile svg {
  margin-right: 10px;
}

.about .content ul li {
  padding: 10px 0 0 28px;
  position: relative;
}

.about .content ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #2e75c7;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .bg-block {
  width: 300px;
  height: auto;
  display: block;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}

.about .bg-block .bg-line {
  width: 300px;
  height: 100%;
  background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0) 78%);
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: 3;
}

.about .bg-block .text-img {
  position: absolute;
  font-family: 'SSTPro';
  font-size: 20px;
  color: #fff;
  padding: 30px;
  display: block;
  z-index: 4;
  max-width: 300px;
}

.about .bg-block img {
  max-width: 100%;
  position: relative;
}

form .form-check-input.checked {
  background-color: #ffffff;
  border-radius: 2px;
}

form .form-check-input:checked[type=checkbox] {
  background-image: url(../img/Check.svg);
  background-size: 8px;
}

form .submit-block {
  margin: 0px 0px;
}

form .submit-block>.d-flex {
  max-width: 70px;
  align-items: center;
}

@media (max-width: 480px) {
  .about .form-registration .step .circule {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    font-size: 18px;
    padding-bottom: 3px;
  }

  .about .form-registration .step p {
    color: #35333c;
    font-size: 14px;
  }

  .Rectangle {
    margin: 20px 16px 18px 0px;
  }

  .about .form-registration form input {
    min-width: 345px;
  }

  .about .bg-block .text-img {
    font-size: 15px;
    padding: 10px;
  }

  .hero-container__info {
    max-width: 100%;
  }

  .carousel-indicators button {
    display: block;
    border: 1px solid #35333c;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background-color: #404040;
  }

  .hero-container__info span {
    font-size: 16px;
  }

  .about .content h2 {
    font-size: 22px;
  }

  .about .content h3 {
    font-size: 16px;
  }

  .bi-person {
    margin-right: 10px;
  }

  .about .form-registration button {
    width: 160px;
    padding: 8px 20px 10px;
  }

  .about .form-registration form button.Submit {
    padding: 10px 20px;
  }

  .services .icon-box {
    margin-bottom: 0px;
  }
}

/*--------------------------------------------------------------
# About List
--------------------------------------------------------------*/
.about-list {
  padding-top: 0;
}

.about-list .icon-box H4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.about-list .icon-box i {
  font-size: 48px;
  float: left;
  color: #2e75c7;
}

.about-list .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.about-list .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  background: url("../img/counts-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 80px 0 60px 0;
  position: relative;
}

.counts::before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.counts .title {
  position: relative;
  color: #fff;
  margin-bottom: 40px;
}

.counts .title h3 {
  font-size: 36px;
  font-weight: 700;
}

.counts .counters span {
  font-size: 48px;
  display: block;
  color: #2e75c7;
}

.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .services .icon-box {
    margin-bottom: 0px;
  }
}

.services .icon-box .box-img {
  display: block;
  height: 32px;
}

.services .icon-box p {
  opacity: 0.8;
}

.services .icon-box i {
  float: left;
  color: #2e75c7;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box .h4 {
  /* H4 fix v3.4 12/15/2023 IID AI16 */
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-family: 'SSTPro';
  line-height: 1.2;
}

.services .icon-box p {
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  font-family: 'SSTPro-Roman';
  max-width: 200px;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 0 35px 0;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 0 15px 15px 0;
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #444444;
  border-radius: 4px;
  text-transform: uppercase;
  background: #fff;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #2e75c7;
  color: #fff;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-wrap img {
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  text-align: center;
  background: rgba(53, 65, 68, 0.6);
  padding-bottom: 30px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links {
  display: flex;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links a {
  color: #2e75c7;
  margin: 0 4px;
  background-color: #fff;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links a i {
  font-size: 18px;
  line-height: 0;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links a:hover {
  background: #2e75c7;
  color: #fff;
}

.portfolio .portfolio-wrap:hover {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  padding-bottom: 0;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2e75c7;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2e75c7;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(53, 65, 68, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
}

.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
}

.team .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

.team .member:hover img {
  transform: scale(1.1);
}

.team .member .member-info {
  padding-left: 30px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #354144;
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #b4c1c4;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #c2cdcf;
}

.team .member .social a i {
  color: #fff;
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  background: #2e75c7;
}

.team .member .social a+a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("../img/testimonials-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(8, 10, 11, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2e75c7;
}

@media (min-width: 1024px) {
  .testimonials {
    background-attachment: fixed;
  }
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contact .info {
  background: #fff;
}

.contact .info i {
  font-size: 24px;
  color: #2e75c7;
  float: left;
  width: 48px;
  height: 48px;
  background: #e2faff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #354144;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #62787d;
}

.contact .info:hover i {
  background: #2e75c7;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #2e75c7;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #0dd3fe;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# info
--------------------------------------------------------------*/
.info {
  /* background-color: #f8f8fa; */
}

.info p,
.info p a {
  color: #35333c;
  font-size: 14px;
  margin: 0;
  font-family: 'SSTPro-Roman';
  text-decoration: none;
}

.info p a:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  .info p {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f6f7f8;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4b5c61;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #000000;
  padding: 22px 0 30px 0;
  color: #fff;
  font-size: 14px;
}

.footer .footer-top {
  background: #2a3336;
  border-bottom: 1px solid #354144;
  padding: 60px 0 30px 0;
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

.footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #354144;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  background: #2e75c7;
  color: #fff;
  text-decoration: none;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #40dcfe;
  font-size: 18px;
  line-height: 1;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
  color: #27d8fe;
}

.footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

.footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #2e75c7;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
}

.footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #0187a4;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple]):not([size]) {
  --bs-form-select-bg-icon: none;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
  background: rgb(220, 53, 69);
  padding: 10px 15px;
  border-radius: 6px;
  color: #fff;
}

.invalid-feedback.form-label {
  display: block;
}

.was-validated .form-control:invalid~label.invalid-feedback,
.was-validated :invalid~.select.invalid-feedback,
.was-validated .form-control:invalid~label.form-label {
  color: #dc3545;
  background: none;
  padding: 0px;
  border-radius: none;
}

.was-validated .form-control:valid~.invalid-feedback,
.was-validated :valid~.select.invalid-feedback,
.was-validated .form-control:valid~label.form-label,
.was-validated :valid~.select.form-label {
  color: green;
}

.about .form-registration form input[type="radio"],
.about .form-registration form input[type="checkbox"] {
  margin-top: 5px;
}

.multi-collapse {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.multi-collapse.show {
  opacity: 1;
}

footer .footer-title {
  padding: 40px 0px 20px;
}

footer .footer-title p {
  font-family: 'SSTPro';
  font-size: 16px;
  color: #878792;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  margin: 0;
}

.footer-links .contact {
  padding-top: 70px;
  padding-bottom: 30px;
}

.footer-links .contact a {
  padding-left: 40px;
  padding-bottom: 0px;
}

.footer-links a {
  font-family: 'SSTPro-Roman';
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  transition: .3s;
  padding-bottom: 10px;
}

.footer-links a:hover {
  opacity: 0.8;
}

.footer-links .footer_btn {
  font-family: 'SSTPro-Roman';
  font-size: 16px;
  color: #ffffff;
  padding: 10px 20px 12px;
  background-color: #3c3c42;
  transition: 0.3s;
}

.footer-links .footer_btn:hover {
  opacity: 0.8;
}

.footer-links .footer_social__links svg {
  width: 24px;
  height: 24px;
  margin-left: 40px;
}

footer hr {
  margin: 26px 0 30px;
  background-color: #3c3c42;
}

.footer_terms a {
  font-family: 'SSTPro';
  font-size: 12px;
  margin-right: 20px;
}

.footer-bottom {}

.footer-bottom p {
  color: rgba(135, 135, 146, 0.8);
  font-family: 'SSTPro';
  font-size: 12px;
  margin: 0;
}

.footer-bottom a {
  transition: 0.3s;
}

.footer-bottom a:hover {
  opacity: 0.8;
}

.footer-bottom img {
  max-width: 150px;
}

#loading {
  background: none;
  color: #35333c;
  display: flex;
  border: none;
  align-items: center;
  justify-content: space-around;
  max-width: 155px;
  margin-top: 49px;
}

#loading span {
  color: #2e75c7;
}

hr.separator {
  margin: 0;
  background-color: #3c3c42;
  opacity: 1;
  border-top: 1px solid #3c3c42;
}

#navbar.navbar .dropdown ul {
  left: 0px;
  background: #3c3c42;
}

#navbar.navbar .dropdown ul li {
  min-width: 120px;
}

#navbarHome.navbar .dropdown ul {
  left: 0;
  background: #3c3c42;
}

#navbarHome.navbar .dropdown ul li:first-child {
  border-bottom: 1px solid #ffffff10;
}

#navbarHome.navbar .dropdown ul li {
  min-width: 164px;
  background: #3c3c42;
  transition: .3s;
}

#navbarHome.navbar .dropdown ul li:hover,
.navbar .dropdown ul li:hover {
  /* opacity: 0.1; */
  background-color: #ffffff10;
}

#navbarHome.navbar a {
  padding: 20px 0 20px 20px;
  display: flex;
  justify-content: flex-start;
  text-transform: inherit;
  min-width: 100%;
  font-family: 'SSTPro-Roman';
}

#navbarHome.navbar:hover {
  --bs-navbar-hover-color: none
}

#navbarHome.navbar a svg {
  margin-right: 10px;
  width: 18px;
}

.bg-menu {
  background-color: #3c3c42;
}

.navbar a:hover {
  --bs-link-hover-color-rgb: none !important;
}

.navbarFooter .footer_btn>a {
  padding: 0;
}

.navbarFooter .footer_btn {
  margin-left: 5px;
}

#thankYou p {
  margin-bottom: 25px;
}

/* #bgImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 600px;
  border: 2px solid #000000;
  background: #cecece;
  border-radius: 5px;
  position: relative;
}

#bgImage::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url('../img/fpo_image.jpg');
  background-size: cover;
  background-position-y: 0;
  background-position-x: center;
} */

/*----------- ---------*/
.open>.dropdown-menu,
.show>.dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 0;
  margin: -1px 0 0 0;
  font-size: 14px;
  color: #333;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0 0 3px 3px;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .08), inset 0 0 0 0.6px #d2d2d2;
  padding: 0.75px;
}

mark {
  background-color: pink;
}


.list-autocomplete {
  padding: 0;
}

.list-autocomplete em {
  font-style: normal;
  background-color: #e1f2f9;
}

.hasNoResults {
  color: #fff;
  font-style: normal;
}

.hasNoResults,
.btn-extra {
  display: block;
  padding: 10px;
}

.btn-extra {
  width: 100%;
  border-top: .5px solid #d2d2d2;
}

/*----------- ---------*/
.block__backing {
  width: 100%;
}

#register .hero-container__info {
  max-width: 94%;
  width: 100%;
  z-index: 3;
  display: block;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .block__backing {
    width: auto;
  }

  #register .hero-container__info {
    position: absolute;
    display: block;
    margin: 0;
    width: auto;
    text-align: start;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  #login .hero-container__info {
    max-width: 100%;
    margin-top: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center
  }

  #login #hero .hero-container__info h2 {
    margin: 20px 0 40px;
    font-family: 'SSTPro-Roman';
    text-align: center;
    max-width: 100%;
    width: 460px;
    font-size: 36px;
  }

  #hero .hero-container__block__btn {
    width: 360px;
  }

  #login .hero-container__info a {
    width: 170px;
  }

  #register .hero-container {}

  #register #hero h2 {
    margin: 30px auto 30px;
    max-width: 485px;
    font-size: 36px;
  }

  #register .about .form-registration .info,
  .about .form-registration form,
  .about .form-registration .multi-collapse,
  .about .form-registration .col-lg-7>.d-flex {
    max-width: 85%;
    margin: 0 auto;
    width: 100%;
  }

  .about .form-registration form input,
  .about .form-registration form .form-select {
    min-width: 280px;
  }
}

@media (max-width: 480px) {
  #register #hero h2 {
    font-size: 28px;
    text-align: left;
  }

  #navbarHome.navbar a {
    padding: 20px 0 20px 0px;
  }

  #navbar button,
  .navbar button {
    margin-left: 0px;
  }

  .navbar {
    padding-right: 0px;
  }

  #navbar.navbar {
    padding-right: 10px;
  }

  #register .about .form-registration .info,
  .about .form-registration form,
  .about .form-registration .multi-collapse,
  .about .form-registration .col-lg-7>.d-flex {
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
  }

  #login .hero-container__info {
    max-width: 94%;
    margin-top: 0px;
    text-align: center;
    display: block;
    flex-direction: column;
    justify-items: center;
    align-items: center
  }

  #login #hero .hero-container__info h2 {
    margin: 20px 0 40px;
    font-family: 'SSTPro-Roman';
    text-align: center;
    max-width: 100%;
    width: 460px;
    font-size: 36px;
  }

  #hero .carousel-inner .bg {
    background-image: linear-gradient(to bottom, black 5%, #d9050500 50%);
  }

  #hero .hero-container__block__btn {
    width: 360px;
  }

  #login .hero-container__info a {
    width: 170px;
  }

  .accordion-flush .accordion-item,
  .accordion-flush .accordion-item .accordion-button,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    background: none;

  }

  .accordion-flush .accordion-item {
    border-bottom: 1px solid #3d3d3d !important;
  }

  .accordion-flush .accordion-item .accordion-button {
    font-size: 16px;
    padding: 20px 12px;
    color: rgb(135, 135, 146);
    font-family: 'SSTPro';
    font-size: 16px;
    color: #878792;
    font-weight: bold;
  }

  .accordion-body {
    color: #ffffff;
    padding-left: 12px;
  }

  .accordion-body a {
    color: #ffffff;
    font-family: 'SSTPro-Roman';
    padding: 6px 0;

  }

  .accordion-button:not(.collapsed) {
    box-shadow: none
  }

  .accordion-button::after {
    content: url(../img/Arrow-bottom.svg);
    background-image: #878792 !important;
    width: 10px;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: #878792 !important;
  }

  footer hr {
    display: none;
  }

  .mobileHr {
    margin: 20px 0px 0px 0px;
    padding-bottom: 20px;
    border-bottom: 1px solid #3d3d3d
  }

  .footer-links .footer_btn {
    width: 150px;
  }

  .mobile.footer-links {
    padding: 0 20px;
  }

  .mobile.footer-links .contact {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .mobile.footer-links .contact a {
    padding-left: 0px;
    padding-bottom: 0px;
    font-size: 12px;
    text-align: center;
    max-width: 100px;
  }

  .footer_social__links a {
    margin: 0px 20px;
  }

  .navbarFooter .footer_btn {
    height: 41px;
    max-width: 60px;
  }

  .navbarFooter {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .form-group__2span {
    grid-column: 1 / span 2;
  }
}

@media (max-width: 480px) {
  #login #hero .carousel-content {
    min-height: 316px;
  }

  #login #hero .carousel-item {
    min-height: 316px;
  }

  #login #hero .carousel-inner .bg {
    min-height: 316px;
    background-image: linear-gradient(to bottom, black 15%, #d9050500 50%);
  }

  #login #hero .carousel-indicators li {
    border: 1px solid #727171;
  }
}

.footer.US {
  display: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: #878792 !important;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, .125);
}

/* custom styles */
.error-field {
  display: block;
  margin-top: 0.25rem;
  font-size: .875em;
  background: rgb(220, 53, 69);
  border-radius: 6px;
  padding: 0.75px;
}

.error-field-text {
  color: #fff;
  font-style: normal;
  display: block;
  padding: 10px;
  font-size: 15px;
}

.info-notifier,
.info-notifier>a {
  color: rgb(220, 53, 69);
  font-size: 14px;
}

.info-notifier>a {
  font-weight: 600;
}

.success-notifier {
  background-color: lightgreen;
  padding: 10px;
  box-shadow: 1px 2px 5px #888888;
  border-radius: 4px;
}

.error-notifier {
  background-color: rgb(253, 148, 165);
  padding: 10px;
  box-shadow: 1px 2px 5px #888888;
  border-radius: 4px;
}

.circule path {
  fill: white;
}