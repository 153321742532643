
.img-container{
    position: relative;
    border-radius: 5px;
        text-align: center;
}
.zv-img{
    height:500px;
    width:100%;
    cursor: pointer;
    border-radius: 5px;
}
.logo-pos{
            position: absolute;
            top: 35px;
            left: 50%;
            transform: translate(-50%, -50%);
            /* margin-bottom: 10px; */
}
.main-text{
    position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        width: 400px;
}
.header-text{
        font-size: 20px;
        font-weight: 800;
    }
    .header-text2{
        font-size: 10px;
    }
    .zv-avatar{
        height: 75px;
        width: 75px;
        border-radius: 50%;
        margin-top: 10px;
}

.zv-btn1{
font-size: 13px !important;
background-color: white !important;
border-radius: 15px !important;
color: black;
}
.zv-btn2{
font-size: 13px !important;
border-radius: 15px !important;
}
.terms{
    margin: .5rem 1.5rem .5rem 1.5rem;
    text-align: justify;
    font-size: 8px;
}