body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', Arial;
}

/* #root {
    padding-bottom: 100px;
} */

a {
    color: rgb(46, 117, 199);
}

a:visited {
    color: rgb(46, 117, 199);
}

/* skip to main content link */
.skip-to-main-content-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
}

.skip-to-main-content-link:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
}

/* Move reCAPTCHA v3 badge in front */
.grecaptcha-badge {
    z-index: 999;
}