.modal {
  --bs-modal-width: 770px;
}
:root {
}

.btn {
  --bs-btn-font-family: 'SSTPro-Roman', sans-serif;
  --bs-btn-font-size: 18px;
  --bs-border-radius: 0px;
}

.btn-outline-secondary {
  --bs-btn-color: #35333c;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ced4da;
  --bs-btn-hover-border-color: #ced4da;
  --bs-btn-focus-shadow-rgb: 108,117,125;
  --bs-btn-active-color: #35333c;
  --bs-btn-active-bg: #ced4da;
  --bs-btn-active-border-color: #ced4da;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ced4da;
  --bs-gradient: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2e75c7;
  --bs-btn-border-color: #2e75c7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0b5ed7;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}