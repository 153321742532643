@media screen and (width > 820px) {
    .tiles {
        max-width: 400px !important;
    }

    .tilesContainer {
        width: 400px !important;
        cursor: pointer;
    }
}

.margin-field {
    margin: 5vh 0 5vh 0 !important;
}

.register-product-image {
    width: 300px;
    height: 200px;
    object-fit: contain;
    border-radius: 5px;
}

.product-title {
    font-family: "SSTPro";
    font-size: 20px;
    font-weight: 500;
    color: #35333c;
    margin: 0;
}